// lazyLoad
[].forEach.call(document.querySelectorAll('img[data-src]'), function (img) {
  img.setAttribute('src', img.getAttribute('data-src'));
  img.onload = function () {
    img.removeAttribute('data-src');
  };
});

const btnDown = () => {
  const btn = document.querySelector('.btn-down');

  if (btn !== null) {
    const block = window.innerHeight - 110;
    btn.addEventListener('click', (e) => {
      e.preventDefault();
      const scrollDown = () => {
        window.scrollBy(0, 20);
        if (window.pageYOffset < block) {
          requestAnimationFrame(scrollDown);
        }
      };
      scrollDown();
    });
  }
}

btnDown();

lightGallery(document.getElementById('videoPopup'), {
  plugins: [lgVideo],
  autoplayFirstVideo:false,
  counter: false,
  mobileSettings: {
    download: false,
    showCloseIcon: true,
  }
});

window.addEventListener('scroll', () => {
  if (document.querySelector('.detail') && window.pageYOffset !== 0) {
    document.querySelector('.header').classList.add('scroll-active');
  }
  if (document.querySelector('.detail') && window.pageYOffset === 0) {
    document.querySelector('.header').classList.remove('scroll-active');
  }
});

if (window.innerWidth < 1280) {
  const medalBlock = document.querySelector('.medal-slider');
  if (medalBlock) {
    const medalItems = medalBlock.querySelectorAll('.swiper-slide'),
      medals = medalBlock.querySelector('.swiper-wrapper');
    if (medalItems.length > 6) {
      let newHeight = 0;
      medalBlock.insertAdjacentHTML('beforeend', `<div class="btn btn-light">Показать еще</div>`);
      medalItems.forEach((i, index) => {
        if (index < 6) {
          newHeight += i.offsetHeight + 20;
        }
      });

      medals.dataset.height = newHeight + 'px';
      medals.style.maxHeight = newHeight + 'px';
    }
    const medalBtn = medalBlock.querySelector('.btn');

    if (medalBtn) {
      medalBtn.addEventListener('click', function () {
        if (!medalBlock.matches('.active')) {
          medalBlock.classList.add('active');
          medalBtn.textContent = 'Свернуть';
          medals.style.maxHeight = medals.scrollHeight + 'px';
        } else {
          medalBlock.classList.remove('active');
          medalBtn.textContent = 'Показать еще';
          medals.style.maxHeight = medals.dataset.height;
        }
      });
    }

  }
}