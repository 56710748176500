const mainSlider = {
  el: '.main-slider',
  options: {
    speed: 1500,
    direction: "vertical",
    keyboard: {
      enabled: true,
      onlyInViewport: true,
      pageUpDown: true,
    },
    mousewheel: {
      sensitivity: 2,
    },
    lazy: {
      loadPrevNext: true,
    },
    on: {
      init: function (swiper) {
        if (swiper.realIndex !== 0) {
          document.querySelector('.header').classList.remote('main-slider-start');
        }
      },
      slideChange: function (swiper) {
        if (swiper.realIndex !== 0) {
          document.querySelector('.header').classList.remove('main-slider-start');
        }
      },
    }
  },
};

const menuSlider = {
  el: '.menu-slider',
  options: {
    slidesPerView: 3,
    navigation: {
      nextEl: '.menu-slider .slider__next',
      prevEl: '.menu-slider .slider__prev',
    },
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
    },
  },
}

const medalSlider = {
  el: '.medal-slider',
  options: {
    slidesPerView: 6,
    spaceBetween: 30,
    navigation: {
      nextEl: '.medal-slider__wrap .slider__next',
      prevEl: '.medal-slider__wrap .slider__prev',
    },
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
    },
  },
};

const sliderAdd = (sliders) => (e) => {
  sliders.forEach(slider => {
    let swiperSlider;
    if (window.innerWidth > 1280) {
      swiperSlider = new Swiper(slider.el, slider.options);
    }
    // if (window.innerWidth <= 1280) {
    //   swiper.destroy();
    // }
  })
}

window.addEventListener("load", sliderAdd([mainSlider, menuSlider, medalSlider]));
// window.addEventListener("resize", sliderAdd([mainSlider, menuSlider, menuSlider]));
