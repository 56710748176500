// попапы
// открытие попапа
const openPopup = (e) => {
  e.preventDefault();

  const target = e.target,
    href = target.closest('a').getAttribute('href').replace("#", ""),
    wrapPopup = document.getElementById(href);

  if (target.closest('.popup__wrap')) {
    target.closest('.popup__wrap').classList.remove('active');
  }

  wrapPopup.style.display = 'flex';
  setTimeout(function () {
    wrapPopup.classList.add('active');
  }, 10);

  document.body.style.overflow = 'hidden';
};

// закрытие попапа
const closePopup = (e) => {
  const target = e.target;
  if (target.closest('.btn-close')) {
    let wrapPopup;
    wrapPopup = target.closest('.popup__wrap');

    wrapPopup.classList.remove('active');
    setTimeout(function () {
      wrapPopup.style.display = '';
    }, 510);

    document.body.style.overflow = '';

  }
};

//кнопки для вызова popup 
const popupBtns = document.querySelectorAll('[data-popup]');

popupBtns.forEach(item => {
  item.addEventListener('click', openPopup);
});

const popupAge = document.getElementById('age');

function getCookie(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

let video = document.getElementById('mainVideo');

if (getCookie("age") === 'yes') {
  popupAge.classList.remove('active');

  if (window.innerWidth >= 1280) {
    if (video) {
      video.play();
      video.addEventListener('ended', function () {
        console.log('end');
        document.querySelector('.header').classList.remove('main-slider-start');
      });
    }
  } else {
    document.querySelector('.header').classList.remove('main-slider-start');
  }
}


if (getCookie("age") !== 'yes' && !popupAge.matches('.active')) {
  popupAge.classList.add('active');
}

popupAge.addEventListener('click', (e) => {
  const target = e.target;
  if (target.matches('.yes')) {
    document.cookie = "age=yes";
    video.play();
  }
  if (target.matches('.no')) {
    document.cookie = "age=no";
  }
});

document.addEventListener('click', closePopup);