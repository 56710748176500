// header
// открытие бургер меню
const openBurgerMenu = (e) => {
  if (!burger.matches('.active')) {
    burger.classList.add('active');
    header.classList.add('menu-active');
    burgerMenu.classList.add('active');
    document.body.style.overflow = 'hidden';
  } else {
    burger.classList.remove('active');
    header.classList.remove('menu-active');
    burgerMenu.classList.remove('active');
    document.body.style.overflow = '';
  }
}

const burgerMenu = document.querySelector('.burger-menu'),
  header = document.querySelector('.header'),
  burger = document.querySelector('.header .burger');

if (burger) {
  burger.addEventListener('click', openBurgerMenu);
}

// открытие подразделов в меню на мобильном
const openSubmenu = (e) => {
  const target = e.target,
    wrap = target.closest('li'),
    submenu = wrap.querySelector('ul');

  if (submenu) {
    if (!wrap.matches('.active')) {
      wrap.classList.add('active');
      submenu.style.maxHeight = submenu.scrollHeight + 40 + 'px';
    } else {
      wrap.classList.remove('active');
      submenu.style.maxHeight = '';
    }
  }
}

const submenuTitle = document.querySelectorAll('.burger-menu .menu li');

if (submenuTitle.length) {
  submenuTitle.forEach(item => {
    item.addEventListener('click', openSubmenu)
  });
}